<template>
    <div class="Refactorinfocus">
        <div class="countdown">
            <div class="clearfix" style="width:100%">
                <div class="div_titles fl">重构焦点</div>
                <div class="count_span fl" v-show="countNum==1">
                    <span>{{count.name}}倒计时：</span>
                    <span class="timer_s">{{timer}}</span>
                </div>
                <button class="count_but fr" @click="submit()"  v-show="searchVal<8">提交</button>
            </div>
        </div>
        <div class="refa_Box">
            <div class="div_box">
                <div class="kehu_ul_div">
                    <ul class="clearfix kehu_ul">
                        <li class="fl kehu_ul_li">
                            <div class="li_div_one margin30">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../assets/img/lan_nail.png" alt="">
                                            <p class="div_p">他做过的</p>
                                            <div class="ri_div">
                                                <el-button type="danger" plain size="mini" @click="emptys('p1')" v-show="form.leader==true">清空</el-button>
                                            </div>
                                        </li>
                                        <li class="li_one_bott" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div :class="index==0?'div_span_ac':'div_span_acs'" 
                                                v-for="(item,index) in forms.p1.values" :key="index" 
                                                v-show="forms.p1.values.length>0"
                                                @mouseover="overp1(index)" @mouseout="outp1()"
                                            >
                                                <div class="conte_div_one">
                                                    <i class="el-icon-close" v-show="form.leader==true & p1Num==index || item.userId == userId & p1Num==index" @click="delBox('p1',item)"></i>
                                                    <span>
                                                        {{index+1}}、{{item.value}}
                                                    </span>
                                                </div>
                                            </div>
                                            <p class="p_i_add" :class="forms.p1.values.length==0?'p_i_adds':''" v-show="forms.p1.values.length<5">
                                                <i class="iconfonts icons-jiahao"  @click="adds('p1')"></i>
                                            </p>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div class="li_div_one">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../assets/img/lan_nail.png" alt="">
                                            <p class="div_p">他担心的</p>
                                            <div class="ri_div">
                                                <el-button type="danger" plain size="mini" @click="emptys('p2')" v-show="form.leader==true">清空</el-button>
                                            </div>
                                        </li>
                                        <li class="li_one_bott" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div :class="index1==0?'div_span_ac':'div_span_acs'" 
                                                v-for="(item1,index1) in forms.p2.values" :key="index1" 
                                                v-show="forms.p2.values.length>0"
                                                @mouseover="overp2(index1)" @mouseout="outp2()"
                                            >
                                                <div class="conte_div_one">
                                                    <i class="el-icon-close" v-show="form.leader==true && p2Num == index1 || item1.userId == userId && p2Num == index1" @click="delBox('p2',item1)"></i>
                                                    <span>
                                                        {{index1+1}}、{{item1.value}}
                                                    </span>
                                                </div>
                                            </div>
                                            <p class="p_i_add" :class="forms.p2.values.length==0?'p_i_adds':''" v-show="forms.p2.values.length<5">
                                                <i class="iconfonts icons-jiahao"  @click="adds('p2')"></i>
                                            </p>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="fl kehu_ul_li">
                            <div class="li_div_two borderTop">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../assets/img/huang_nail.png" alt="">
                                            <p class="div_p">我想到的</p>
                                            <div class="ri_div">
                                                <el-button type="danger" plain size="mini" @click="emptys('p3')" v-show="form.leader==true">清空</el-button>
                                            </div>
                                        </li>
                                        <li class="li_one_bott li_one_botts" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div class="div_span_ac" 
                                                v-for="(item2,index2) in forms.p3.values" :key="index2" 
                                                v-show="forms.p3.values.length>0"
                                                @mouseover="overp3(index2)" @mouseout="outp3()"
                                            >
                                                <div class="conte_div_one">
                                                    <i class="el-icon-close" v-show="form.leader==true && p3Num == index2 || item2.userId == userId && p3Num == index2" @click="delBox('p3',item2)"></i>
                                                    <span>
                                                        {{index2+1}}、{{item2.value}}
                                                    </span>
                                                </div>
                                            </div>
                                            <p class="p_i_add p_i_adds" v-show="forms.p3.values.length<5">
                                                <i class="iconfonts icons-jiahao"  @click="adds('p3')"></i>
                                            </p>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div class="li_div_two">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../assets/img/huang_nail.png" alt="">
                                            <p class="div_p">我听见的</p>
                                            <div class="ri_div">
                                                <el-button type="danger" plain size="mini" @click="emptys('p4')" v-show="form.leader==true">清空</el-button>
                                            </div>
                                        </li>
                                        <li class="li_one_bott li_one_botts" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div class="div_span_ac" 
                                                v-for="(item3,index3) in forms.p4.values" :key="index3" 
                                                v-show="forms.p4.values.length>0"
                                                @mouseover="overp4(index3)" @mouseout="outp4()"
                                            >
                                                <div class="conte_div_one">
                                                    <i class="el-icon-close" v-show="form.leader==true && p4Num == index3 || item3.userId == userId && p4Num == index3" @click="delBox('p4',item3)"></i>
                                                    <span>
                                                        {{index3+1}}、{{item3.value}}
                                                    </span>
                                                </div>
                                            </div>
                                            <p class="p_i_add p_i_adds" v-show="forms.p4.values.length<5">
                                                <i class="iconfonts icons-jiahao"  @click="adds('p4')"></i>
                                            </p>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div class="li_div_two borderBottom">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../assets/img/huang_nail.png" alt="">
                                            <p class="div_p">我看见的</p>
                                            <div class="ri_div">
                                                <el-button type="danger" plain size="mini" @click="emptys('p5')" v-show="form.leader==true">清空</el-button>
                                            </div>
                                        </li>
                                        <li class="li_one_bott li_one_botts" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div class="div_span_ac" 
                                                v-for="(item4,index4) in forms.p5.values" :key="index4" 
                                                v-show="forms.p5.values.length>0"
                                                @mouseover="overp5(index4)" @mouseout="outp5()"
                                            >
                                                <div class="conte_div_one">
                                                    <i class="el-icon-close" v-show="form.leader==true && p5Num == index4 || item4.userId == userId && p5Num == index4" @click="delBox('p5',item4)"></i>
                                                    <span>
                                                        {{index4+1}}、{{item4.value}}
                                                    </span>
                                                </div>
                                            </div>
                                            <p class="p_i_add p_i_adds" v-show="forms.p5.values.length<5">
                                                <i class="iconfonts icons-jiahao"  @click="adds('p5')"></i>
                                            </p>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="fl kehu_ul_li">
                            <div class="li_div_one margin30">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../assets/img/lan_nail.png" alt="">
                                            <p class="div_p">他兴奋的</p>
                                            <div class="ri_div">
                                                <el-button type="danger" plain size="mini" @click="emptys('p6')" v-show="form.leader==true">清空</el-button>
                                            </div>
                                        </li>
                                        <li class="li_one_bott" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div :class="index5==0?'div_span_ac':'div_span_acs'" 
                                                v-for="(item5,index5) in forms.p6.values" :key="index5" 
                                                v-show="forms.p6.values.length>0"
                                                @mouseover="overp6(index5)" @mouseout="outp6()"
                                            >
                                                <div class="conte_div_one">
                                                    <i class="el-icon-close" v-show="form.leader==true && p6Num == index5 || item5.userId == userId && p6Num == index5" @click="delBox('p6',item5)"></i>
                                                    <span>
                                                        {{index5+1}}、{{item5.value}}
                                                    </span>
                                                </div>
                                            </div>
                                            <p class="p_i_add" :class="forms.p6.values.length==0?'p_i_adds':''" v-show="forms.p6.values.length<5">
                                                <i class="iconfonts icons-jiahao"  @click="adds('p6')"></i>
                                            </p>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div class="li_div_one">
                                <div class="ul_li_div">
                                    <ul class="ul_li_one">
                                        <li class="li_one_top">
                                            <img src="../../assets/img/lan_nail.png" alt="">
                                            <p class="div_p">他说过的</p>
                                            <div class="ri_div">
                                                <el-button type="danger" plain size="mini" @click="emptys('p7')" v-show="form.leader==true">清空</el-button>
                                            </div>
                                        </li>
                                        <li class="li_one_bott" v-for="item in 5" :key="item">
                                            <p></p>
                                        </li>
                                        <div class="conte_div_p">
                                            <div :class="index6==0?'div_span_ac':'div_span_acs'" 
                                                v-for="(item6,index6) in forms.p7.values" :key="index6" 
                                                v-show="forms.p7.values.length>0"
                                                @mouseover="overp7(index6)" @mouseout="outp7()"
                                            >
                                                <div class="conte_div_one">
                                                    <i class="el-icon-close" v-show="form.leader==true && p7Num == index6 || item6.userId == userId && p7Num == index6" @click="delBox('p7',item6)"></i>
                                                    <span>
                                                        {{index6+1}}、{{item6.value}}
                                                    </span>
                                                </div>
                                            </div>
                                            <p class="p_i_add" :class="forms.p7.values.length==0?'p_i_adds':''" v-show="forms.p7.values.length<5">
                                                <i class="iconfonts icons-jiahao"  @click="adds('p7')"></i>
                                            </p>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="refact_popup">
            <el-dialog
                :title="titleName"
                :visible.sync="dialogVisible"
                width="500px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('ruleForm')"></i>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small" @submit.native.prevent>
                    <el-form-item label="内容" prop="value">
                        <el-input type="textarea" v-model="ruleForm.value" placeholder="请输入内容" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="closei('ruleForm')">取消</el-button>
                    <el-button type="primary" size="mini" @click="subxit('ruleForm')">确定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    props: ['searchVals'],
    data() {
        return {
            searchVal:0,
            dialogVisible:false,
            timer:'',
            countNum:0,
            count:{},
            titleName:'',
            ruleForm:{
                value:''
            },
            rules:{
                value:[
                    { required: true, message: '内容不能为空', trigger:'blur'}
                ]
            },
            form:{},
            forms:{
                p1:{
                    values:[]
                },
                p2:{
                    values:[]
                },
                p3:{
                    values:[]
                },
                p4:{
                    values:[]
                },
                p5:{
                    values:[]
                },
                p6:{
                    values:[]
                },
                p7:{
                    values:[]
                }
            },
            userId:'',
            p1Num:-1,
            p2Num:-1,
            p3Num:-1,
            p4Num:-1,
            p5Num:-1,
            p6Num:-1,
            p7Num:-1
        }
    },
    methods: {
        getapp(){
            this.searchVal = this.searchVals
            this.userId = this.$store.state.user_id
            this.axios.StuCprojectgets3({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.forms = res.data.data.data
                    var str = this.forms.resource
                    this.$emit('info', str);
                }else{
                    this.$message.error(res.data.message)
                    this.form = {}
                }
            }).catch(err=>{

            })
        },
            // 获取倒计时
        down(){
            this.axios.StudctdGet({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    if(res.data.data!='' &&  res.data.data!=null && res.data.data!='null'){
                        this.countNum = 1
                        this.count = res.data.data
                        this.time = res.data.data.times
                        clearTimeout(this.timeouts)
                        if(this.count.state==1){
                            this.countDown()
                            this.timeoutss = setTimeout(this.down,5000)
                        }else{
                            let h = parseInt(this.time / (60 * 60) % 24);
                            h = h < 10 ? "0" + h : h
                            let m = parseInt(this.time / 60 % 60);
                            m = m < 10 ? "0" + m : m
                            let s = parseInt(this.time % 60);
                            s = s < 10 ? "0" + s : s
                            this.timer =  m +':'+ s
                            this.timeoutss = setTimeout(this.down,5000)
                        }
                    }else{
                        this.timeout = setTimeout(this.down,3000)
                        this.countNum = 2
                    }
                }else if(res.data.code==-4054){
                    this.timeout = setTimeout(this.down,3000)
                    this.countNum = 2
                    // this.$message.error(res.data.message)
                }else{
                  this.countNum = 2
                }
            }).catch(err=>{

            })
        },
        //倒计时
        countDown(){
            if(this.time>0){
                let h = parseInt(this.time / (60 * 60) % 24);
                h = h < 10 ? "0" + h : h
                let m = parseInt(this.time / 60 % 60);
                m = m < 10 ? "0" + m : m
                let s = parseInt(this.time % 60);
                s = s < 10 ? "0" + s : s
                this.timer =  m +':'+ s
                this.time--
                this.timeouts = setTimeout(this.countDown,1000)
            }else{
                this.timer = '00' +':'+ '00' +':'+ '00'
            }
        },
        adds(val){
            this.ruleForm.node = val
            if(val == 'p1'){
                this.titleName = '他做过的'
            }else if(val == 'p2'){
                this.titleName = '他担心的'
            }else if(val == 'p3'){
                this.titleName = '我想到的'
            }else if(val == 'p4'){
                this.titleName = '我听见的'
            }else if(val == 'p5'){
                this.titleName = '我看见的'
            }else if(val == 'p6'){
                this.titleName = '他兴奋的'
            }else if(val == 'p7'){
                this.titleName = '他说过的'
            }
            this.dialogVisible = true
        },
        //鼠标移入
        overp1(val){
            this.p1Num = val
        },
        outp1(){
            this.p1Num = -1
        },
        //鼠标移入
        overp2(val){
            this.p2Num = val
        },
        outp2(){
            this.p2Num = -1
        },
        //鼠标移入
        overp3(val){
            this.p3Num = val
        },
        outp3(){
            this.p3Num = -1
        },
        //鼠标移入
        overp4(val){
            this.p4Num = val
        },
        outp4(){
            this.p4Num = -1
        },
        //鼠标移入
        overp5(val){
            this.p5Num = val
        },
        outp5(){
            this.p5Num = -1
        },
        //鼠标移入
        overp6(val){
            this.p6Num = val
        },
        outp6(){
            this.p6Num = -1
        },
        //鼠标移入
        overp7(val){
            this.p7Num = val
        },
        outp7(){
            this.p7Num = -1
        },
        subxit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.token = this.$store.state.token
                    this.ruleForm.id = this.$store.state.cla_id
                    this.axios.StuCprojectsets3(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==0){
                            this.dialogVisible = false
                            this.getapp()
                            this.$refs[formName].resetFields();
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        // 输入框回车事件
        enterInput(e){
            if (e.which == 13 || e.charCode == 13 || e.keyCode == 13) {
                e.preventDefault() // 阻止浏览器默认换行操作
                this.subxit('ruleForm')
            }
        },
        // 提交
        submit(){
            this.axios.submits3({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    this.getapp()
                    this.$message.success('提交成功')
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 清空数据
        emptys(val){
            this.$confirm('清空后无法找回哦', '确定清空', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.StuCprojectdels3({
                    id:this.$store.state.cla_id,
                    token:this.$store.state.token,
                    node:val,
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        //删除某一条数据
        delBox(val,item){
            this.$confirm('删除后无法找回哦', '确定删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                if(val=='p1'){ 
                    if(this.form.leader==true){
                        const arr = this.forms.p1.values.filter(infos => infos.value != item.value)
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:arr
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:[item]
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                }else if(val=='p2'){ 
                    if(this.form.leader==true){
                        const arr1 = this.forms.p2.values.filter(infos => infos.value != item.value)
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:arr1
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:[item]
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                }else if(val=='p3'){
                    if(this.form.leader==true){
                        const arr2 = this.forms.p3.values.filter(infos => infos.value != item.value)
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:arr2
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:[item]
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                }else if(val=='p4'){
                    if(this.form.leader==true){
                        const arr3 = this.forms.p4.values.filter(infos => infos.value != item.value)
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:arr3
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:[item]
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                    
                }else if(val=='p5'){
                    if(this.form.leader==true){
                        const arr4 = this.forms.p5.values.filter(infos => infos.value != item.value)
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:arr4
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:[item]
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                }else if(val=='p6'){
                    if(this.form.leader==true){
                        const arr5 = this.forms.p6.values.filter(infos => infos.value != item.value)
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:arr5
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:[item]
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                }else if(val=='p7'){
                    if(this.form.leader==true){
                        const arr6 = this.forms.p7.values.filter(infos => infos.value != item.value)
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:arr6
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.axios.StuCprojectdels3({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:[item]
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                }
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.$refs[formName].resetFields();
        },
        handleClose(done){

        }
    },
    beforeDestroy () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearTimeout(that.timeoutss)
        clearInterval(that.scont);
        that.scont = null;
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null
    },
    destroyed () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearInterval(that.scont);
        clearTimeout(that.timeoutss)
        that.scont = null;
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null
    },
    mounted() {
        this.getapp()
        this.down()
        let that = this;
        that.scont = window.setInterval(() => {
            setTimeout(() => {
                that.getapp()
            }, 0)
        },5000)
    }
}
</script>
<style scoped>
@import './../../assets/css/student/Refactorinfocus.css';
</style>
<style>
.refact_popup .el-dialog__body{
    padding: 30px 50px 10px;
}
.refact_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.refact_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}
.refact_popup .el-form-item__content{
    margin-left: 0!important;
}
.refact_popup .el-form-item__label{
    font-weight: bold;
    color: #333;
    width: 100%;
    text-align: left;
}
.refact_popup .el-form-item--small.el-form-item{
    margin-bottom: 12px;
}
.refact_popup .el-dialog__footer{
    text-align: center;
}
.refact_popup .el-button--mini{
    width: 80px;
}
.ri_div .el-button--mini{
    padding: 5px 15px;
}
.ri_div .el-button--danger.is-plain{
    border-color: #999;
    color: #333;
    background: transparent;
}
.ri_div .el-button--danger.is-plain:hover {
    /* background: #f31b1b; */
    border-color: #ed4848;
    color: #f31b1b;
}
</style>